import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  type SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { type FC, memo, useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@hooks';
import { setActiveStep } from '@modules/bonusManagement/store/bonusesSlice';
import { useValidationStep } from '@modules/bonusManagement/submodules/bonus/hooks/useValidationStep';
import { useEditBonusMutation } from '@modules/bonusManagement/submodules/bonus/service/bonusDetailsApi';

export interface IStep {
  label: string;
  disabled: boolean;
  optional: boolean;
  hasError?: boolean;
}

export interface IStepProps {
  activeStep: number;
}

export interface IStepper {
  steps: IStep[];
  isNonLinear?: boolean;
  hideActionButtons?: boolean;
  renderSteps: Array<FC<IStepProps>>;
  handleChangeStep?: (step: number) => void;
}

interface ISvgColorParams {
  disabled?: boolean;
  hasError?: boolean;
  isActive?: boolean;
}

const getTextSX = (disabled: boolean): SxProps<Theme> => {
  return {
    ...(disabled && { opacity: 0.5 }),
    fontWeight: 600,
  };
};

function HorizontalStepper({
  steps,
  renderSteps,
  handleChangeStep,
  isNonLinear = false,
  hideActionButtons = false,
}: IStepper) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const activeStep = useAppSelector(
    (state) => state.bonusesModule.bonusConfig.activeStep
  );

  const [, { isLoading: isLoadingEditBonus }] = useEditBonusMutation({
    fixedCacheKey: 'editBonus',
  });

  const { isLoadingValidateWizardStep } = useValidationStep(isNonLinear);

  const totalSteps = useMemo(() => steps.length, [steps]);
  const isLastStep = useMemo(
    () => activeStep === totalSteps - 1,
    [activeStep, totalSteps]
  );

  const Component = useMemo(() => {
    const ComponentType = renderSteps[activeStep];
    return ComponentType ? <ComponentType activeStep={activeStep} /> : <></>;
  }, [activeStep, renderSteps]);

  const handleNext = useCallback(() => {
    if (!isLastStep) {
      dispatch(setActiveStep(activeStep + 1));
    }
  }, [isLastStep, activeStep, dispatch]);

  const handleBack = useCallback(() => {
    if (activeStep !== 1) {
      dispatch(setActiveStep(activeStep - 1));
    }
  }, [activeStep, dispatch]);

  const handleClickStep = useCallback(
    (step: number) => () => {
      if (step !== activeStep) {
        handleChangeStep?.(step);
      }
    },
    [activeStep, handleChangeStep]
  );

  const actionButtons = useCallback(
    () =>
      hideActionButtons ? null : (
        <Box sx={{ paddingTop: 2, display: 'flex', flexDirection: 'row' }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button disabled={isLastStep} onClick={handleNext}>
            Next
          </Button>
        </Box>
      ),
    [isLastStep, activeStep, handleBack, handleNext, hideActionButtons]
  );

  const getSvgColor = useCallback(
    ({ disabled, hasError, isActive }: ISvgColorParams) => ({
      color: theme.palette.grey[700],
      ...(hasError &&
        !isActive && {
          color: theme.palette.error.main,
        }),
      ...(disabled && {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[300]
            : theme.palette.grey[200],
      }),
    }),
    [theme.palette.grey, theme.palette.mode, theme.palette.error]
  );

  const getLabelColor = useCallback(
    (hasError = false) => ({
      ...(hasError && {
        color: theme.palette.error.main,
      }),
    }),
    [theme.palette.error]
  );

  useEffect(() => {
    return () => {
      dispatch(setActiveStep(0));
    };
  }, [dispatch]);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map(({ label, disabled, optional, hasError }, index) => (
          <Step disabled={disabled} key={label}>
            <StepButton
              disabled={
                disabled || isLoadingEditBonus || isLoadingValidateWizardStep
              }
              sx={{
                padding: '0 1rem',
                cursor: 'pointer',
                '& svg': {
                  ...getSvgColor({
                    disabled,
                    hasError,
                    isActive: activeStep === index,
                  }),
                  '& > text': {
                    fontWeight: 700,
                    ...(theme.palette.mode === 'dark' && {
                      fill: theme.palette.common.black,
                    }),
                  },
                },
              }}
              color="inherit"
              onClick={handleClickStep(index)}
            >
              <Typography
                sx={{
                  ...getTextSX(disabled),
                  ...getLabelColor(hasError),
                }}
              >
                {label}
              </Typography>
              {optional && (
                <Typography
                  sx={{
                    fontSize: '0.65rem',
                    ...getTextSX(disabled),
                  }}
                >
                  optional
                </Typography>
              )}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        sx={{
          marginTop: '1rem',
        }}
      >
        {Component}
        {actionButtons()}
      </Box>
    </Box>
  );
}

export default memo(HorizontalStepper);
