import { FORM_ELEMENT, OPERATOR_TYPE } from '@features/filters/types';
import { type IFiltersInitialValues } from '@modules/playerCodes/types';

export enum FILTERS_ENTITY {
  ACTIVATED_AT = 'activatedAt-At',
  ACTIVATED_TO = 'activatedAt-To',
  APPLIED_AT = 'appliedAt-At',
  APPLIED_TO = 'appliedAt-To',
  BRAND_ID = 'brandId',
  CAMPAIGN_ID = 'campaignId',
  CANCELLED_AT = 'cancelledAt-At',
  CANCELLED_TO = 'cancelledAt-To',
  CODE = 'code',
  COUNTRY_ID = 'countryId',
  EXPIRES_AT = 'expiresAt-At',
  EXPIRES_TO = 'expiresAt-To',
  FILTER_TYPE = 'filterType',
  ID = 'id',
  PLAYER_ID = 'playerId',
  STATUS = 'status',
}

export const filterList = [
  {
    label: 'Player ID',
    value: FILTERS_ENTITY.PLAYER_ID,
    formElement: FORM_ELEMENT.INPUT,
  },
  {
    label: 'Cancelled At From',
    value: FILTERS_ENTITY.CANCELLED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Cancelled At To',
    value: FILTERS_ENTITY.CANCELLED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Code ID',
    value: FILTERS_ENTITY.ID,
    formElement: FORM_ELEMENT.INPUT,
  },
  {
    label: 'Code',
    value: FILTERS_ENTITY.CODE,
    formElement: FORM_ELEMENT.INPUT,
  },
  {
    label: 'Brand',
    value: FILTERS_ENTITY.BRAND_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Country',
    value: FILTERS_ENTITY.COUNTRY_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Status',
    value: FILTERS_ENTITY.STATUS,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
  {
    label: 'Applied At From',
    value: FILTERS_ENTITY.APPLIED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Applied At To',
    value: FILTERS_ENTITY.APPLIED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Activated At From',
    value: FILTERS_ENTITY.ACTIVATED_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Activated At To',
    value: FILTERS_ENTITY.ACTIVATED_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Expires At From',
    value: FILTERS_ENTITY.EXPIRES_AT,
    formElement: FORM_ELEMENT.DATE_RANGE_START,
  },
  {
    label: 'Expires At To',
    value: FILTERS_ENTITY.EXPIRES_TO,
    formElement: FORM_ELEMENT.DATE_RANGE_END,
  },
  {
    label: 'Campaign Name',
    value: FILTERS_ENTITY.CAMPAIGN_ID,
    formElement: FORM_ELEMENT.AUTOCOMPLETE,
  },
];

export const filtersInitialValues: IFiltersInitialValues = {
  [FILTERS_ENTITY.FILTER_TYPE]: null,
  [FILTERS_ENTITY.ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.STATUS]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.CODE]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.BRAND_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.COUNTRY_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.CAMPAIGN_ID]: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
  [FILTERS_ENTITY.PLAYER_ID]: {
    value: '',
    inversion: false,
    operator: OPERATOR_TYPE.EQUAL,
  },
  [FILTERS_ENTITY.APPLIED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.CANCELLED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACTIVATED_TO]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.APPLIED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.EXPIRES_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.CANCELLED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
  [FILTERS_ENTITY.ACTIVATED_AT]: {
    value: null,
    inversion: false,
    operator: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
  },
};

export const inputTypeFilters = [
  FILTERS_ENTITY.PLAYER_ID,
  FILTERS_ENTITY.CODE,
  FILTERS_ENTITY.ID,
];

export const startDateTypeFilters = [
  FILTERS_ENTITY.APPLIED_AT,
  FILTERS_ENTITY.EXPIRES_AT,
  FILTERS_ENTITY.ACTIVATED_AT,
  FILTERS_ENTITY.CANCELLED_AT,
];

export const endDateTypeFilters = [
  FILTERS_ENTITY.APPLIED_TO,
  FILTERS_ENTITY.EXPIRES_TO,
  FILTERS_ENTITY.ACTIVATED_TO,
  FILTERS_ENTITY.CANCELLED_TO,
];

export enum PLAYER_CODES_QUERY_PARAM {
  PLAYER_ID = 'player_id',
}
