import { OPERATOR_TYPE } from '@features/filters/types';

export const scheduledCampaignTextLabel = {
  uploadControl: '.csv file max to 2 MB',
  uploadControlTitle: 'Click or Drag to upload file',
  excludedPlayers: 'Amount of Players excluded from the campaign:',
  applicablePlayers: 'Amount of Players applicable for the campaign:',
  unsavedChanges: 'There are unsaved changes, would you like to continue?',
  uploadFileHint:
    'The file will be validated after completion of the creation/modification process.',
  tabDisableConfirmation:
    'The change will cause the reset of previously selected conditions. Continue?',
  emptySportOrCasinoConditionsError:
    'Please specify Casino or Sports Conditions for the KPI calculation',
  additionalInfoNoSegment:
    "This section applies to campaigns that use the 'Segment' target group option.",
  activatingStatus:
    'Activating will make the campaign fully functional for the targeted players. Continue?',
  deactivatingStatus:
    'Deactivating will make the campaign not functional for the targeted players. Continue?',
  emptyExecutionSchedule:
    'The execution schedule will be displayed here after populating Execution Conditions.',
  repeatOptionsTooltip:
    'To specify how many days, weeks, or months\n' +
    'between each occurrence of the event',
  changeBrandPopup:
    'The change will cause the reset of any previously selected bonuses for tiers, sports and casino conditions data.\n' +
    'Continue?',
  creationCampaignTooltip:
    "The selected option KPI will serve as the main segmentation criterion and will be used to calculate the player's cashback amount.",
  activitiesPeriodTooltip:
    'Indicating the period of time within which the activities related to primary criterion have to take place to be considered in the player`s tier evaluation.',
  nextOccurrenceListWarning:
    "The schedule takes effect when the campaign is activated; otherwise, it's just a forecast of the schedule, as it will not be executed until the campaign is activated.",
  uploadFileTooltip:
    'Supported file format is CSV containing Player IDs only. Remember that each individual ID must be placed on a new and separate row. The uploaded file will be validated after completion of the campaign creation/modification process.',
  uploadTypeTooltipOptions: [
    `Adds the new data to an existing source file/table (if any). Any fields that don't exist in the current table design will be added.`,
    `Overwrites the data of the existing source file/table and load the new data into it.`,
  ],
  calculationTypeTooltip:
    "Specify the calculation type to be used. If 'Based on Relative Period' is selected, the system will calculate activities independently for each period. If 'Based on Campaign Lifetime Period' is chosen, the system will calculate the player's total activity from the campaign start and subtract any rewards already given (excluding free spins).",
};

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  bonusName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  enabled: { value: false, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  primaryCriterion: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
};

export enum EXECUTION_CONDITIONS_FIELD {
  REPEAT = 'repeatOptions',
  START_DATE = 'startDateTime',
  STOP_REPEAT = 'stopRepeat',
}
