import { Grid2, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { LuRefreshCw } from 'react-icons/lu';
import { useRouteError } from 'react-router-dom';

import * as Sentry from '@sentry/react';

export const ErrorComponent = () => {
  const theme = useTheme();
  const routeError = useRouteError();

  useEffect(() => {
    if (routeError) {
      Sentry.captureException(routeError);
    }
  }, [routeError]);

  return (
    <Grid2
      container
      sx={{
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      direction="column"
      spacing={0}
    >
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LuRefreshCw color={theme.palette.primary.dark} size="9rem" />
        <Typography
          sx={{
            paddingTop: '4rem',
          }}
          variant="h2"
        >
          We have got a new version ready.
        </Typography>
        <Typography
          sx={{
            paddingTop: '1rem',
          }}
          variant="h2"
        >
          We will do refresh for you!
        </Typography>
      </Stack>
    </Grid2>
  );
};
