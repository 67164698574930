import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import {
  type IBranchesPayload,
  type IConditionRow,
  type ISearchSportsData,
  type ISportsResponseData,
} from '@modules/common/sportsData/types';
import { type Option } from '@shared/form/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { responseToSelectOptions } from '@utils/responseFormatters';

export const sportsDataServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'sportsDataService',
  baseQuery: (...args) => baseQueryCreator(ENV.VITE_BONUS_API_URL)(...args),
});

export const sportsDataApi = sportsDataServiceSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBranches: builder.query<IConditionRow[], IBranchesPayload>({
      query: (payload) => ({
        body: payload,
        method: 'POST',
        url: API.SPORTS_DATA.BRANCHES,
      }),
    }),
    getSports: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getSports',
      query: ({ brandIds }) => ({
        url: API.SPORTS_DATA.SEARCH_SPORTS,
        params: {
          brandIds,
        },
      }),
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
    }),
    getGamePeriods: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getGamePeriods',
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
      query: ({ brandIds, sportIds }) => ({
        url: API.SPORTS_DATA.SEARCH_GAME_PERIODS,
        params: {
          brandIds,
          sportIds,
        },
      }),
    }),
    getMarkets: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getMarkets',
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
      query: ({ eventIds, sportIds, brandIds, leagueIds }) => ({
        url: API.SPORTS_DATA.SEARCH_MARKETS,
        params: {
          sportIds,
          brandIds,
          ...(eventIds?.length && { eventIds }),
          ...(leagueIds?.length && { leagueIds }),
        },
      }),
    }),
    getMarketCategories: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getMarketCategories',
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
      query: ({ brandIds, sportIds, eventIds, leagueIds }) => ({
        url: API.SPORTS_DATA.SEARCH_MARKET_CATEGORIES,
        params: {
          brandIds,
          sportIds,
          ...(eventIds?.length && { eventIds }),
          ...(leagueIds?.length && { leagueIds }),
        },
      }),
    }),
    getLeagues: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getLeagues',
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
      query: ({ eventIds, sportIds, brandIds, leagueIds, searchTerm }) => ({
        url: API.SPORTS_DATA.SEARCH_LEAGUES,
        params: {
          sportIds,
          brandIds,
          searchTerm,
          ...(eventIds?.length && { eventIds }),
          ...(leagueIds?.length && { leagueIds }),
        },
      }),
    }),
    getEvents: builder.query<Option[], Partial<ISearchSportsData>>({
      serializeQueryArgs: () => 'getEvents',
      transformResponse: (response: ISportsResponseData[]) =>
        responseToSelectOptions<ISportsResponseData[]>(response, {
          key: 'name',
        }),
      query: ({
        state,
        sportIds,
        brandIds,
        leagueIds,
        participantSearchTerm,
      }) => ({
        url: API.SPORTS_DATA.SEARCH_EVENTS,
        params: {
          state,
          sportIds,
          brandIds,
          participantSearchTerm,
          ...(leagueIds?.length && { leagueIds }),
        },
      }),
    }),
  }),
});

export const {
  useLazyGetSportsQuery,
  useLazyGetEventsQuery,
  useLazyGetLeaguesQuery,
  useLazyGetMarketsQuery,
  useLazyGetBranchesQuery,
  useLazyGetGamePeriodsQuery,
  useLazyGetMarketCategoriesQuery,
} = sportsDataApi;

export type SportsDataQueryKey =
  | 'getEvents'
  | 'getSports'
  | 'getMarkets'
  | 'getLeagues'
  | 'getBranches'
  | 'getGamePeriods'
  | 'getMarketCategories';
