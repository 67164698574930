import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const PlayerCodesReport = Suspendable(
  lazy(() =>
    import('@modules/playerCodes/controller').then((module) => ({
      default: module.default,
    }))
  )
);

const PlayerCodeDetails = Suspendable(
  lazy(() =>
    import('@modules/playerCodes/submodules/PlayerCodeDetails/controller').then(
      (module) => ({
        default: module.default,
      })
    )
  )
);

export const PlayerCodesRoutes: RouteObject[] = [
  {
    element: <PlayerCodesReport />,
    path: ROUTES.PLAYER_CODES.ROOT,
  },
  {
    element: <PlayerCodeDetails />,
    path: ROUTES.PLAYER_CODES.DETAILS,
  },
];
