import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type FC, memo } from 'react';

import CopyLinkButton from '@modules/common/CopyLinkButton';

interface ITitleWithClipboard {
  id: string;
  title: string;
  tooltipTitle: string;
}

const TitleWithCopy: FC<ITitleWithClipboard> = ({
  id,
  title,
  tooltipTitle,
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography variant="h3">{title}</Typography>
      <CopyLinkButton
        withAnimation
        id={id}
        sxProps={{ '&:hover': { background: theme.palette.grey['50'] } }}
        tooltipTitle={tooltipTitle}
      />
    </Stack>
  );
};

export default memo(TitleWithCopy);
