import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const ActivateCodeController = Suspendable(
  lazy(() =>
    import('@modules/activateCode/controller').then((module) => ({
      default: module.default,
    }))
  )
);

export const ActivateCode: RouteObject[] = [
  {
    path: ROUTES.ACTIVATE_CODE.ROOT,
    element: <ActivateCodeController />,
  },
];
