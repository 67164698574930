import { alpha, IconButton, type SxProps, Tooltip } from '@mui/material';
import { Box } from '@mui/material';
import { type Theme, useTheme } from '@mui/material/styles';
import { type FC, memo, type MouseEvent, useCallback, useRef } from 'react';
import { ImLink } from 'react-icons/im';
import { toast } from 'react-toastify';

import { POST_MESSAGE_EVENT_TYPE } from '@modules/types';
import { sendPostMessage } from '@utils/sendPostMessage';

interface ICopyLinkButton {
  id: string;
  tooltipTitle: string;
  withAnimation: boolean;
  sxProps?: SxProps<Theme>;
}

const CopyLinkButton: FC<ICopyLinkButton> = ({
  id,
  sxProps = {},
  tooltipTitle,
  withAnimation,
}) => {
  const buttonRef = useRef<null | HTMLButtonElement>(null);

  const theme = useTheme();

  const animateCopyIcon = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.classList.add('animate');
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.classList.remove('animate');
        }
      }, 300);
    }
  }, []);

  const handleCopyId = useCallback(
    (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      animateCopyIcon();
      sendPostMessage(POST_MESSAGE_EVENT_TYPE.COPY_EDIT_ID, id);
      toast.success('Link is copied.');
    },
    [animateCopyIcon, id]
  );

  return (
    <Tooltip disableInteractive color="secondary" title={tooltipTitle}>
      <IconButton
        sx={{
          marginLeft: '0.5rem',
          position: 'relative',
          paddingTop: '0.613rem',
          display: 'inline-block',
          '&.animate .ripple': {
            animation: 'ripple-effect 0.6s ease-out',
          },
          '@keyframes ripple-effect': {
            '0%': {
              opacity: 1,
              transform: 'scale(0)',
            },
            '100%': {
              opacity: 0,
              transform: 'scale(1)',
            },
          },
          '& .ripple': {
            opacity: 0,
            width: '140%',
            height: '140%',
            top: '-0.25rem',
            left: '-0.5rem',
            borderRadius: '50%',
            position: 'absolute',
            transform: 'scale(0)',
            pointerEvents: 'none',
            backgroundColor: `${alpha(theme.palette.primary.main, 0.4)}`,
          },
          ...sxProps,
        }}
        color="primary"
        onClick={handleCopyId}
        ref={buttonRef}
        size="medium"
      >
        <ImLink />
        {withAnimation ? <Box className="ripple" component="span" /> : null}
      </IconButton>
    </Tooltip>
  );
};

export default memo(CopyLinkButton);
