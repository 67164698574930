import { createBrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { ROUTES } from '@constants';
import { NotFound } from '@pages/404';
import ModuleRoutes from '@routes/ModuleRoutes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  [
    ModuleRoutes,
    {
      path: ROUTES.ALL,
      element: <NotFound />,
    },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_startTransition: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);
