import { BiTargetLock } from 'react-icons/bi';
import { BsCurrencyExchange, BsFillEyeFill } from 'react-icons/bs';
import { FaBusinessTime } from 'react-icons/fa';
import { FiActivity, FiSettings } from 'react-icons/fi';
import { GoFileCode } from 'react-icons/go';
import { GrSchedules } from 'react-icons/gr';
import { LuCode } from 'react-icons/lu';
import { PiFrameCornersBold } from 'react-icons/pi';
import { SiMarketo } from 'react-icons/si';
import {
  TbAward,
  TbManualGearbox,
  TbReportAnalytics,
  TbReportSearch,
  TbTextScan2,
} from 'react-icons/tb';

import { ROUTES } from '@constants';
import { GROUP_TITLE, type IMenuItem } from '@features/menu/menuSlice';

const menuIconsConfigMap = {
  activateCode: LuCode,
  campaigns: SiMarketo,
  playerBonuses: TbAward,
  appSettings: FiSettings,
  playerCodes: GoFileCode,
  reports: TbReportSearch,
  codeCampaigns: TbTextScan2,
  iframeMode: PiFrameCornersBold,
  scheduledCampaigns: GrSchedules,
  playerOffers: TbReportAnalytics,
  giveBonusManagement: BiTargetLock,
  manualAssignment: TbManualGearbox,
  campaignActivityReport: FiActivity,
  automaticCampaigns: FaBusinessTime,
  bonusManagement: BsCurrencyExchange,
  campaignMonitorReport: BsFillEyeFill,
};

export const menuConfig: IMenuItem[] = [
  {
    id: 'campaigns',
    type: 'group',
    title: GROUP_TITLE.CAMPAIGNS,
    children: [
      {
        id: 'campaigns',
        type: 'collapse',
        breadcrumbs: true,
        title: GROUP_TITLE.CAMPAIGNS,
        icon: menuIconsConfigMap.campaigns,
        children: [
          {
            id: 'automatic-campaigns',
            type: 'item',
            breadcrumbs: true,
            title: 'Automatic Campaigns',
            url: ROUTES.AUTOMATIC_CAMPAIGNS.ROOT,
            icon: menuIconsConfigMap.automaticCampaigns,
          },
          {
            id: 'scheduled-campaigns',
            type: 'item',
            breadcrumbs: true,
            title: 'Scheduled Campaigns',
            url: ROUTES.SCHEDULED_CAMPAIGNS.ROOT,
            icon: menuIconsConfigMap.scheduledCampaigns,
          },
          {
            id: 'code-campaigns',
            type: 'item',
            breadcrumbs: true,
            title: 'Code Campaigns',
            url: ROUTES.CODE_CAMPAIGNS.ROOT,
            icon: menuIconsConfigMap.codeCampaigns,
          },
        ],
      },
    ],
  },
  {
    id: 'bonus-management',
    type: 'item',
    breadcrumbs: true,
    title: 'Bonus Management',
    url: ROUTES.BONUS_MANAGEMENT.ROOT,
    icon: menuIconsConfigMap.bonusManagement,
  },
  {
    id: 'manual-assignments',
    type: 'group',
    title: GROUP_TITLE.MANUAL_ASSIGNMENTS,
    children: [
      {
        id: 'manual-assignments',
        type: 'collapse',
        breadcrumbs: true,
        title: GROUP_TITLE.MANUAL_ASSIGNMENTS,
        icon: menuIconsConfigMap.manualAssignment,
        children: [
          {
            id: 'give-bonus',
            type: 'item',
            breadcrumbs: true,
            title: 'Give Bonus',
            url: ROUTES.GIVE_BONUS_MANAGEMENT.ROOT,
            icon: menuIconsConfigMap.giveBonusManagement,
          },
          {
            id: 'activate-code',
            type: 'item',
            breadcrumbs: true,
            title: 'Add Code',
            url: ROUTES.ACTIVATE_CODE.ROOT,
            icon: menuIconsConfigMap.activateCode,
          },
        ],
      },
    ],
  },
  {
    id: 'reports',
    type: 'group',
    title: GROUP_TITLE.REPORTS,
    children: [
      {
        id: 'reports',
        type: 'collapse',
        breadcrumbs: true,
        title: GROUP_TITLE.REPORTS,
        icon: menuIconsConfigMap.reports,
        children: [
          {
            id: 'player-bonuses',
            type: 'item',
            breadcrumbs: true,
            title: 'Bonuses Report',
            url: ROUTES.PLAYER_BONUSES.ROOT,
            icon: menuIconsConfigMap.playerBonuses,
          },
          {
            id: 'player-offers',
            type: 'item',
            breadcrumbs: true,
            title: 'Offers Report',
            url: ROUTES.PLAYER_OFFERS.ROOT,
            icon: menuIconsConfigMap.playerOffers,
          },
          {
            id: 'player-codes',
            type: 'item',
            breadcrumbs: true,
            title: 'Codes Report',
            url: ROUTES.PLAYER_CODES.ROOT,
            icon: menuIconsConfigMap.playerCodes,
          },
          {
            id: 'monitor-report',
            type: 'item',
            breadcrumbs: true,
            title: 'Monitor Report',
            url: ROUTES.CAMPAIGN_MONITOR_REPORT.ROOT,
            icon: menuIconsConfigMap.campaignMonitorReport,
          },
          {
            id: 'activity-report',
            type: 'item',
            breadcrumbs: true,
            title: 'Activity Report',
            url: ROUTES.CAMPAIGN_ACTIVITY_REPORT.ROOT,
            icon: menuIconsConfigMap.campaignActivityReport,
          },
        ],
      },
    ],
  },
  {
    id: 'app-settings',
    type: 'item',
    breadcrumbs: true,
    title: 'App Settings',
    url: ROUTES.APP_SETTINGS.ROOT,
    icon: menuIconsConfigMap.appSettings,
  },
  {
    id: 'iframe-mode',
    type: 'item',
    breadcrumbs: true,
    title: 'Iframe Mode',
    url: ROUTES.IFRAME_MODE.ROOT,
    icon: menuIconsConfigMap.iframeMode,
  },
];

export const menuItems = {
  items: menuConfig,
};
